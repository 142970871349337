import React, { useState, useEffect } from 'react';
import { Bar } from 'react-chartjs-2';
import axios from 'axios';
import moment from 'moment';
import 'chart.js/auto';
import DatabaseNavigation from './DatabaseNavigation';

const getDateRange = (timeFrame) => {
  const today = moment();
  let fromDate, toDate;

  if (timeFrame === 'months') {
    fromDate = moment().month(4).startOf('month').format('YYYY-MM-DD');
    toDate = moment().month(8).endOf('month').format('YYYY-MM-DD');
  } else if (timeFrame === 'weeks') {
    fromDate = today.clone().subtract(4, 'weeks').startOf('week').format('YYYY-MM-DD');
    toDate = today.clone().endOf('week').format('YYYY-MM-DD');
  } else if (timeFrame === 'days') {
    fromDate = today.clone().startOf('month').format('YYYY-MM-DD');
    toDate = today.clone().endOf('month').format('YYYY-MM-DD');
  }

  return { fromDate, toDate };
};

const fetchData = async (fromDate, toDate, agentId, campaignId) => {
  const url = `http://statistics-staging.viribuzmedia.com/umbraco/Api/ViribuzAgentDatabase/GetLineChartByDateRange?DateFrom=${fromDate}&DateTo=${toDate}&agentId=${agentId}&campaignId=${campaignId}`;
  try {
    const response = await axios.get(url);
    
    const filteredData = response.data.filter(item => ![2512, 2534, 2539].includes(item.AgentId));
    return filteredData;
  } catch (error) {
    console.error('Error fetching data:', error);
    return [];
  }
};

const DatabaseRetainersTotal = ({ selectedAgent, selectedCampaign, timeFrame, onAgentChange, onCampaignChange, onTimeFrameChange }) => {
  const [totalRetainers, setTotalRetainers] = useState(0);
  const [agents, setAgents] = useState([]);
  const [campaigns, setCampaigns] = useState([]);
  const [data, setData] = useState({
    labels: ['Total', 'NEC', 'Camp Lejeune', 'MVA', 'Talc', 'APAP', 'B2B', 'NEC Acquisition', 'Round Up'],
    datasets: [
      {
        label: 'Retainers',
        data: [0, 0, 0, 0, 0, 0, 0, 0, 0],
        backgroundColor: [
          'rgba(128, 128, 128, 0.6)', // Grey
          'rgba(173, 255, 47, 0.6)',  // Green
          'rgba(255, 165, 0, 0.6)',   // Orange
          'rgba(135, 206, 235, 0.6)', // Light Blue
          'rgba(218, 112, 214, 0.6)', // Purple
          'rgba(255, 99, 132, 0.6)',  // Red
          'rgba(54, 162, 235, 0.6)',  // Blue
          'rgba(255, 206, 86, 0.6)',  // Yellow
          'rgba(75, 192, 192, 0.6)'   // Teal
        ],
        borderColor: [
          'rgba(128, 128, 128, 1)',
          'rgba(173, 255, 47, 1)',
          'rgba(255, 165, 0, 1)',
          'rgba(135, 206, 235, 1)',
          'rgba(218, 112, 214, 1)',
          'rgba(255, 99, 132, 1)',
          'rgba(54, 162, 235, 1)',
          'rgba(255, 206, 86, 1)',
          'rgba(75, 192, 192, 1)'
        ],
        borderWidth: 1,
      },
    ],
  });

  useEffect(() => {
    const fetchChartData = async () => {
      const { fromDate, toDate } = getDateRange(timeFrame);
      const agentId = selectedAgent;

      const totalData = await fetchData(fromDate, toDate, agentId, 0);
      const necData = await fetchData(fromDate, toDate, agentId, 2190);
      const campLejeuneData = await fetchData(fromDate, toDate, agentId, 2289);
      const mvaData = await fetchData(fromDate, toDate, agentId, 2188);
      const talcData = await fetchData(fromDate, toDate, agentId, 2194);
      const apapData = await fetchData(fromDate, toDate, agentId, 2447);
      const b2bData = await fetchData(fromDate, toDate, agentId, 2580);
      const necAcquisitionData = await fetchData(fromDate, toDate, agentId, 2596);
      const roundUpData = await fetchData(fromDate, toDate, agentId, 2601);

      const totalRetainers = totalData.reduce((acc, curr) => acc + curr.TotalRetainersAcquired, 0);
      const necRetainers = necData.reduce((acc, curr) => acc + curr.TotalRetainersAcquired, 0);
      const campLejeuneRetainers = campLejeuneData.reduce((acc, curr) => acc + curr.TotalRetainersAcquired, 0);
      const mvaRetainers = mvaData.reduce((acc, curr) => acc + curr.TotalRetainersAcquired, 0);
      const talcRetainers = talcData.reduce((acc, curr) => acc + curr.TotalRetainersAcquired, 0);
      const apapRetainers = apapData.reduce((acc, curr) => acc + curr.TotalRetainersAcquired, 0);
      const b2bRetainers = b2bData.reduce((acc, curr) => acc + curr.TotalRetainersAcquired, 0);
      const necAcquisitionRetainers = necAcquisitionData.reduce((acc, curr) => acc + curr.TotalRetainersAcquired, 0);
      const roundUpRetainers = roundUpData.reduce((acc, curr) => acc + curr.TotalRetainersAcquired, 0);

      setTotalRetainers(totalRetainers);

      setData({
        labels: ['Total', 'NEC', 'CAMP', 'MVA', 'TALC', 'APAP', 'B2B', 'NEC Acq', 'Round Up'],
        datasets: [
          {
            label: 'Retainers',
            data: [
              totalRetainers,
              necRetainers,
              campLejeuneRetainers,
              mvaRetainers,
              talcRetainers,
              apapRetainers,
              b2bRetainers,
              necAcquisitionRetainers,
              roundUpRetainers
            ],
            backgroundColor: [
              'rgba(128, 128, 128, 0.6)', // Grey
              'rgba(173, 255, 47, 0.6)',  // Green
              'rgba(255, 165, 0, 0.6)',   // Orange
              'rgba(135, 206, 235, 0.6)', // Light Blue
              'rgba(218, 112, 214, 0.6)', // Purple
              'rgba(255, 99, 132, 0.6)',  // Red
              'rgba(54, 162, 235, 0.6)',  // Blue
              'rgba(255, 206, 86, 0.6)',  // Yellow
              'rgba(75, 192, 192, 0.6)'   // Teal
            ],
            borderColor: [
              'rgba(128, 128, 128, 1)',
              'rgba(173, 255, 47, 1)',
              'rgba(255, 165, 0, 1)',
              'rgba(135, 206, 235, 1)',
              'rgba(218, 112, 214, 1)',
              'rgba(255, 99, 132, 1)',
              'rgba(54, 162, 235, 1)',
              'rgba(255, 206, 86, 1)',
              'rgba(75, 192, 192, 1)'
            ],
            borderWidth: 1,
          },
        ],
      });
    };

    fetchChartData();
  }, [selectedAgent, timeFrame]);

  useEffect(() => {
    fetchAgents();
    fetchCampaigns();
  }, []);

  const fetchAgents = async () => {
    try {
      const token = localStorage.getItem('ViribuzAccessToken');

      if (!token) {
        console.error('No access token found in localStorage');
        return;
      }

      const excludedEmails = [
        'admin@mail.com',
        'casper@mail.com',
        'agent@mail.com',
        'testagentx@agent.com',
        'testagenty@agent.com',
        'string',
        'rogeradmin@agent.com'
      ];

      const response = await axios.get('http://statistics-staging.viribuzmedia.com/umbraco/Api/ViribuzAgent/GetAgents', {
        headers: {
          'ViribuzAccessToken': token,
        }
      });

      const filteredAgents = response.data.data
        .filter(agent => !agent.isAdmin)
        .filter(agent => !excludedEmails.includes(agent.email));

      setAgents(filteredAgents);
    } catch (error) {
      console.error('Error fetching agents:', error);
    }
  };

  const fetchCampaigns = async () => {
    try {
      const response = await axios.get('http://statistics-staging.viribuzmedia.com/umbraco/Api/ViribuzAgentMain/GetCampaigns');

      const sortedCampaigns = response.data.campaigns.sort((a, b) => a.name.localeCompare(b.name));

      const allCampaignOption = { id: '0', name: 'All Campaigns' };
      setCampaigns([allCampaignOption, ...sortedCampaigns]);
    } catch (error) {
      console.error('Error fetching campaigns:', error);
    }
  };

  return (
    <div style={{ width: '100%', height: '100%', maxHeight: '500px' }}>
      <div className="d-flex justify-content-between mb-3">
        <h3 style={{ color: '#a2a9b2' }}>Retainers</h3>
        <h3 style={{ color: '#a2a9b2' }}>Total: {totalRetainers}</h3>
      </div>
      <div style={{ height: '400px', width: '100%' }}>
        <Bar 
          data={data} 
          options={{ 
            responsive: true, 
            maintainAspectRatio: false, 
            scales: { y: { beginAtZero: true } } 
          }} 
        />
      </div>
    </div>
  );
};

export default DatabaseRetainersTotal;
