import React, { useState, useEffect } from 'react';
import { Row, Col, Card, CardHeader, CardBody, CardTitle } from "reactstrap";
import { Form } from 'react-bootstrap';
import axios, { AxiosRequestConfig } from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileDownload  } from '@fortawesome/free-solid-svg-icons';

import NoPhoneCalls from "../../assets/icons/NoPhoneCalls.png";
import NoMessageSent from "../../assets/icons/NoMessageSent.png";
import NoEmailsSent from "../../assets/icons/NoEmailsSent.png";
import NoContactReachTotal from "../../assets/icons/NoContactReachTotal.png";
import NoDQ from "../../assets/icons/NoDQ.png";
import NoApptSet from "../../assets/icons/NoApptSet.png";
import NoRetainers from "../../assets/icons/NoRetainers.png";
import NoDocsSent from "../../assets/icons/NoDocsSent.png";
import NoofShowedAppts from "../../assets/icons/NoofShowedAppts.png";
import NoofRetainersSet from "../../assets/icons/NoofRetainersSet.png";
import NoofLiveTransfersSent from "../../assets/icons/NoofLiveTransfersSent.png";
import DatePicker from 'react-datepicker';
import { FaCalendar } from 'react-icons/fa';
import Swal from 'sweetalert2';
import fs from 'fs';
// import XLSX from "xlsx";
import * as XLSX from 'xlsx';

import "./databaseanalytics.css";

const CustomDatePicker = ({ selected, onChange, placeholder, minDate, popperPlacement }) => (
  <div className="datepicker-wrapper">
    <label>{placeholder}:</label>
    <div className="custom-datepicker-input">
      <DatePicker
        selected={selected}
        onChange={onChange}
        dateFormat="MM/dd/yyyy"
        placeholderText={`Select ${placeholder}`}
        customInput={<CustomInput />}
        minDate={minDate}
        popperPlacement={popperPlacement}
      />
    </div>
  </div>
);

const CustomInput = React.forwardRef(({ value, onClick }, ref) => (
  <div className="custom-input" onClick={onClick} ref={ref}>
    <FaCalendar className="calendar-icon" />
    {value}
  </div>
));

function renderCard(title, icon, value) {
  const isLiveTransfersCard = title === "# of Live Transfers Sent";
  const cardStyle = { height: '122px', maxHeight: '200px' };
  const titleStyle = isLiveTransfersCard ? { fontSize: '14px' } : {};

  return (
    <Col lg="2">
      <Card className="card-chart bg-white card-with-box-shadow" style={cardStyle}>
        <CardHeader>
          <div className="card-header-content">
            <img src={icon} alt={title} className="card-icon" style={{ marginBottom: '20px' }} />
            <div>
              <h5 className="card-category" style={titleStyle}>{title}</h5>
              <CardTitle tag="h3" className="card-value">
                {value}
              </CardTitle>
            </div>
          </div>
        </CardHeader>
        <CardBody>
          {/* Content for the card */}
        </CardBody>
      </Card>
    </Col>
  );
}

function Analytics() {
  const [analyticsData, setAnalyticsData] = useState(null);
  const [agents, setAgents] = useState([]);
  const [selectedAgent, setSelectedAgent] = useState('');

  const today = new Date();
  const past30Days = new Date(today);
  past30Days.setDate(today.getDate() - 30);

  const [startDate, setStartDate] = useState(past30Days);
  const [endDate, setEndDate] = useState(today);

  useEffect(() => {
    const start = past30Days.toLocaleDateString('en-CA');
    const end = today.toLocaleDateString('en-CA');
    fetchData(start, end);
    fetchAgents();
  }, []);

  const fetchAgents = async () => {
    try {
      const token = localStorage.getItem('ViribuzAccessToken');
      const response = await axios.get('http://statistics-staging.viribuzmedia.com/umbraco/Api/ViribuzAgent/GetAgents', {
        headers: { 
          'ViribuzAccessToken': token,
        }
      });
      if (response.data) {
        setAgents(response.data.data);
      }
    } catch (error) {
      console.error('Error fetching agents:', error);
    }
  };

  const fetchData = async (startDate, endDate) => {
    try {
      const response = await axios.get(`http://statistics-staging.viribuzmedia.com/umbraco/Api/ViribuzAgentDatabase/GetAnalytics?startDate=${startDate}&endDate=${endDate}`);
      if (response.data) {
        setAnalyticsData(response.data.data);
      }
    } catch (error) {
      console.error('Error fetching analytics data:', error);
    }
  };

  const fetchAndExportAnalytics = async (startDate, endDate, agentIdStr) => {
    const url = `http://statistics-staging.viribuzmedia.com/umbraco/api/ViribuzAgentDatabase/ExportAgentsDatabaseAnalytics?StartDate=${startDate}&EndDate=${endDate}&AgentIdsStr=${agentIdStr}`;

    const config = {method: 'POST', url: url};
    const response = await axios(config);

    const worksheet = XLSX.utils.json_to_sheet(response.data.data);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
    const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    const blob = new Blob([excelBuffer], {type: 'application/octet-stream'});

    let excelFilename = `${Date.now()}.xls`;
    let downloadUrl = URL.createObjectURL(blob);

    let elemA = document.createElement('a');
    elemA.href = downloadUrl;
    elemA.setAttribute('download', excelFilename);
    document.body.appendChild(elemA);
    elemA.click();
    document.body.removeChild(elemA);
  };

  const returnTransformedDate = async (date) => {
    let dayDD = `0${date.getDate()}`.slice(-2);
    let monthMM = `0${date.getMonth() + 1}`.slice(-2);
    let year = date.getFullYear().toString();
    let transformedDate = `${year}-${monthMM}-${dayDD}`;

    return transformedDate;
  };

  const handleStartDateChange = (date) => {
    setStartDate(date);
    if (date > endDate) {
      setEndDate(date);
      fetchData(date.toLocaleDateString('en-CA'), date.toLocaleDateString('en-CA'));
    } else {
      fetchData(date.toLocaleDateString('en-CA'), endDate.toLocaleDateString('en-CA'));
    }
  };

  const handleEndDateChange = (date) => {
    setEndDate(date);
    fetchData(startDate.toLocaleDateString('en-CA'), date.toLocaleDateString('en-CA'));
  };

  const handleAgentFilterChange = (e) => {
    setSelectedAgent(e.target.value);
  };

  const handleExportAnalytics = async (e) => {
    await fetchAndExportAnalytics(await returnTransformedDate(startDate), await returnTransformedDate(endDate), 0);
  };

  return (
    <div className="container-fluid" style={{ paddingLeft: '5rem', marginTop: '2rem', marginBottom: '3rem', marginLeft: '1rem' }}>
      <Row>
        <h3 style={{ color: '#101828', marginLeft: '1rem', marginBottom: '1rem' }}>Intake Analytics</h3>
      </Row>

      <Row className="align-items-center">
        <Col md="4">
          <Form.Control
            as="select"
            onChange={handleAgentFilterChange}
            style={{ width: '200px' }}
            value={selectedAgent}
          >
            <option value="">All Agents</option>
            {agents
              .sort((a, b) => {
                const nameA = `${a.firstName} ${a.lastName}`.toUpperCase();
                const nameB = `${b.firstName} ${b.lastName}`.toUpperCase();
                return nameA.localeCompare(nameB);
              })
              .map((agent) => (
                <option key={`${agent.firstName}-${agent.lastName}`} value={`${agent.firstName} ${agent.lastName}`}>
                  {`${agent.firstName} ${agent.lastName}`}
                </option>
              ))}
          </Form.Control>
        </Col>

        <Col md="8" className="d-flex justify-content-end align-items-center">
          <FontAwesomeIcon icon={ faFileDownload } className="ml-2" style={{ marginRight: '20px', paddingTop: '5px', cursor: 'pointer' }} size="2x" onClick={handleExportAnalytics} />

          <CustomDatePicker
            selected={startDate}
            onChange={handleStartDateChange}
            placeholder="From"
          />

          <CustomDatePicker
            selected={endDate}
            onChange={handleEndDateChange}
            placeholder="To"
            minDate={startDate}
            popperPlacement="bottom-end"
          />
        </Col>
      </Row>
      
      <br />

      {/* First Row */}
      <Row>
        {analyticsData && (
          <>
            {renderCard("# Phone Calls", NoPhoneCalls, analyticsData.PhoneCalls)}
            {renderCard("# Message Sent", NoMessageSent, analyticsData.MessagesSent)}
            {renderCard("# Emails Sent", NoEmailsSent, analyticsData.EmailsSent)}
            {renderCard("# Contact Reach", NoContactReachTotal, analyticsData.ContactsReachTotal)}
            {renderCard("# DQs", NoDQ, analyticsData.Disqualifieds)}
            {renderCard("# Appt Set", NoApptSet, analyticsData.ApptsSet)}
          </>
        )}
      </Row>
      {/* Second Row */}
      <Row>
        {analyticsData && (
          <>
            {renderCard("# Retainers", NoRetainers, analyticsData.Retainers)}
            {renderCard("# Docs Sent", NoDocsSent, analyticsData.DocsSent)}
            {renderCard("# of Showed Appts", NoofShowedAppts, analyticsData.ShowedAppts)}
            {renderCard("# of Retainers Set", NoofRetainersSet, analyticsData.RetainersSet)}
            {renderCard("# of Live Transfers Sent", NoofLiveTransfersSent, analyticsData.LiveTransfersSent)}
          </>
        )}
      </Row>
    </div>
  );
}

export default Analytics;