import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Modal, Button, Form, Dropdown } from 'react-bootstrap';
import Swal from 'sweetalert2';
import editIcon from '../../assets/icons/edit.png';

const EditButton = ({ rowData, fetchData, currentPage, medicalRecordOptions, retainerPushedOptions }) => {
  const [showModal, setShowModal] = useState(false);
  const [formData, setFormData] = useState({});
  const [campaignOptions, setCampaignOptions] = useState([]);
  const [lawFirmOptions, setLawFirmOptions] = useState([]);

  const turnbackOptions = ["Yes", "No"];

  useEffect(() => {
    if (showModal) {
      fetchRetainerDetails();
      fetchCampaigns();
      fetchFirms();
    }
  }, [showModal]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const fetchRetainerDetails = async () => {
    try {
      const response = await axios.get(`http://statistics-staging.viribuzmedia.com/umbraco/Api/ViribuzAgentRetainer/GetRetainersById/${rowData.Id}`);
      const { data } = response.data;
      if (data.length > 0) {
        setFormData(data[0]);
      }
    } catch (error) {
      console.error("Error fetching retainer details:", error);
    }
  };

  const fetchCampaigns = async () => {
    try {
      const response = await axios.get('http://statistics-staging.viribuzmedia.com/umbraco/Api/ViribuzAgentMain/GetCampaigns');
      const { campaigns } = response.data;
      const sortedCampaigns = campaigns.sort((a, b) => a.name.localeCompare(b.name));
      setCampaignOptions(sortedCampaigns);
    } catch (error) {
      console.error("Error fetching campaigns:", error);
    }
  };

  const fetchFirms = async () => {
    try {
      const response = await axios.get('http://statistics-staging.viribuzmedia.com/umbraco/Api/ViribuzAgentMain/GetFirms');
      const { firms } = response.data;
      const sortedFirms = firms.sort((a, b) => a.name.localeCompare(b.name));
      setLawFirmOptions(sortedFirms);
    } catch (error) {
      console.error("Error fetching firms:", error);
    }
  };

  const submitForm = async () => {
    try {
      console.log('Submitting form data:', formData);
      const response = await axios.post('http://statistics-staging.viribuzmedia.com/umbraco/Api/ViribuzAgentRetainer/PostRetainerUpdate', formData, {
        headers: {
          'Content-Type': 'application/json'
        }
      });
      if (response.data && response.data.success) {
        Swal.fire({
          icon: 'success',
          title: 'Success',
          text: 'Submission successful!',
        });
        fetchData(currentPage);
        setShowModal(false);
      }
    } catch (error) {
      console.error('Error submitting data:', error);
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: error.response ? error.response.data.message : 'Something went wrong!',
      });
    }
  };

  const closeModal = () => {
    setShowModal(false);
  };

  return (
    <>
      <img
        src={editIcon}
        alt="Edit"
        onClick={() => setShowModal(true)}
        style={{
          cursor: 'pointer',
          filter: 'brightness(1.2)',
          transition: 'filter 0.3s ease-in-out',
          borderRadius: '20%',
        }}
        className="retainer-icon-hover-glow"
      />
      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Edit Retainer</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId="formDateRetained">
              <Form.Label>Date Retained:</Form.Label>
              <Form.Control
                type="date"
                name="DateRetained"
                value={formData.DateRetained || ''}
                onChange={handleChange}
              />
            </Form.Group>

            {/* New Turnback Dropdown */}
            <Form.Group controlId="formTurnback">
              <Form.Label>Turnback:</Form.Label>
              <Form.Control
                as="select"
                name="Turnback"
                value={formData.Turnback || ''}
                onChange={handleChange}
              >
                <option value="">Select Turnback</option>
                {turnbackOptions.map((option) => (
                  <option key={option} value={option}>
                    {option}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>

            {/* New Turnback Reason Input */}
            <Form.Group controlId="formTurnbackReason">
              <Form.Label>Turnback Reason:</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter Turnback Reason"
                name="TurnbackReason"
                value={formData.TurnbackReason || ''}
                onChange={handleChange}
              />
            </Form.Group>

            {/* New Turnback Date Picker */}
            <Form.Group controlId="formTurnbackDate">
              <Form.Label>Turnback Date:</Form.Label>
              <Form.Control
                type="date"
                name="TurnbackDate"
                value={formData.TurnbackDate || ''}
                onChange={handleChange}
              />
            </Form.Group>



            
            <Form.Group controlId="formCampaignId">
              <Form.Label>Campaign:</Form.Label>
              <Dropdown onSelect={(selectedOption) => setFormData((prevData) => ({ ...prevData, CampaignId: selectedOption }))}>
                <Dropdown.Toggle variant="success" id="dropdown-basic" style={{ width: '100%', border: '1px solid #cbcbcb' }}>
                  {formData.CampaignId || 'Select Campaign'}
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  {campaignOptions.map((option) => (
                    <Dropdown.Item key={option.id} eventKey={option.id}>
                      {option.name}
                    </Dropdown.Item>
                  ))}
                </Dropdown.Menu>
              </Dropdown>
            </Form.Group>

            <div className="row mb-3">
              <div className="col-6">
                <Form.Group controlId="formLawFirmId">
                  <Form.Label>Law Firm:</Form.Label>
                  <Dropdown onSelect={(selectedOption) => setFormData((prevData) => ({ ...prevData, LawFirmId: selectedOption }))}>
                    <Dropdown.Toggle variant="success" id="dropdown-basic" style={{ width: '100%', border: '1px solid #cbcbcb' }}>
                      {formData.LawFirmId || 'Select Law Firm'}
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      {lawFirmOptions.map((option) => (
                        <Dropdown.Item key={option.id} eventKey={option.id}>
                          {option.name}
                        </Dropdown.Item>
                      ))}
                    </Dropdown.Menu>
                  </Dropdown>
                </Form.Group>
              </div>
              <div className="col-6">
                <Form.Group controlId="formRetainer">
                  <Form.Label>Retainer/LT:</Form.Label>
                  <Dropdown onSelect={(selectedOption) => setFormData((prevData) => ({ ...prevData, RetainerLT: selectedOption }))}>
                    <Dropdown.Toggle variant="success" id="dropdown-basic" style={{ width: '100%', border: '1px solid #cbcbcb' }}>
                      {formData.RetainerLT || 'Select Retainer/LT'}
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      {medicalRecordOptions.map((option) => (
                        <Dropdown.Item key={option} eventKey={option}>
                          {option}
                        </Dropdown.Item>
                      ))}
                    </Dropdown.Menu>
                  </Dropdown>
                </Form.Group>
              </div>
            </div>

            <Form.Group controlId="formFirstName">
              <Form.Label>First Name:</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter First Name"
                name="FirstName"
                value={formData.FirstName || ''}
                onChange={handleChange}
              />
            </Form.Group>
            <Form.Group controlId="formLastName">
              <Form.Label>Last Name:</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter Last Name"
                name="LastName"
                value={formData.LastName || ''}
                onChange={handleChange}
              />
            </Form.Group>

            <Form.Group controlId="formRetainerMethod">
              <Form.Label>Retainer Method:</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter Retainer Method"
                name="RetainerMethod"
                value={formData.RetainerMethod || ''}
                onChange={handleChange}
              />
            </Form.Group>

            <Form.Group controlId="formSystemUsed">
              <Form.Label>System Used:</Form.Label>
              <Form.Control
                as="select"
                name="SystemUsed"
                value={formData.SystemUsed || ''}
                onChange={handleChange}
              >
                <option value="">Select Option</option>
                <option value="GHL">GHL</option>
                <option value="Open Phone">Open Phone</option>
                <option value="Dialer">Dialer</option>
                <option value="Personal Phone">Personal Phone</option>
              </Form.Control>
            </Form.Group>

            <div className="row mb-3">
              <div className="col-6">
                <Form.Group controlId="formMedicalRecord">
                  <Form.Label>Medical Record:</Form.Label>
                  <Dropdown onSelect={(selectedOption) => setFormData((prevData) => ({ ...prevData, MedicalRecord: selectedOption }))}>
                    <Dropdown.Toggle variant="success" id="dropdown-basic" style={{ width: '100%', border: '1px solid #cbcbcb' }}>
                      {formData.MedicalRecord || 'Select Option'}
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      {medicalRecordOptions.map((option) => (
                        <Dropdown.Item key={option} eventKey={option}>
                          {option}
                        </Dropdown.Item>
                      ))}
                    </Dropdown.Menu>
                  </Dropdown>
                </Form.Group>
              </div>
              <div className="col-6">
                <Form.Group controlId="formRetainerPushed">
                  <Form.Label>Retainer Pushed:</Form.Label>
                  <Dropdown onSelect={(selectedOption) => setFormData((prevData) => ({ ...prevData, RetainerPushed: selectedOption }))}>
                    <Dropdown.Toggle variant="success" id="dropdown-basic" style={{ width: '100%', border: '1px solid #cbcbcb' }}>
                      {formData.RetainerPushed || 'Select Option'}
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      {retainerPushedOptions.map((option) => (
                        <Dropdown.Item key={option} eventKey={option}>
                          {option}
                        </Dropdown.Item>
                      ))}
                    </Dropdown.Menu>
                  </Dropdown>
                </Form.Group>
              </div>
            </div>
          </Form>
        </Modal.Body>
        <Modal.Footer style={{ marginBottom: '1rem', marginRight: '1rem' }}>
          <Button
            variant="secondary"
            onClick={closeModal}
            style={{ border: '1px solid #cbcbcb' }}
          >
            Cancel
          </Button>
          <Button
            variant="primary"
            onClick={submitForm}
            style={{ backgroundColor: '#7F56D9', borderColor: '#7F56D9', color: 'white' }}
          >
            Submit
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default EditButton;
