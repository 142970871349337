import editIcon from '../../assets/icons/edit.png';
import delIcon from '../../assets/icons/delete.png';
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import DatePicker from 'react-datepicker';
import { Table, Form, Card, Button, Pagination, Container, Row, Col } from 'react-bootstrap';
import { FaCalendar } from 'react-icons/fa';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowUpZA } from '@fortawesome/free-solid-svg-icons';
import 'react-datepicker/dist/react-datepicker.css';
import './mainagenttable.css';
import MainAgentTableSort from './MainAgentTableSort.js';
import Swal from 'sweetalert2';
import EditButton from './EditButton';

const CustomInput = React.forwardRef(({ value, onClick }, ref) => {
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => setIsHovered(true);
  const handleMouseLeave = () => setIsHovered(false);

  return (
    <div
      className="custom-input"
      onClick={onClick}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      ref={ref}
      style={{
        alignItems: 'center',
        border: '1px solid #a2a9b2',
        borderRadius: '4px',
        boxShadow: isHovered ? '0 0 10px 1px #df00ff' : 'none',
        cursor: 'pointer',
        display: 'flex',
        padding: '8px',
      }}
    >
      <FaCalendar className="calendar-icon" style={{ marginRight: '8px' }} />
      {value || 'Select Start Date'}
    </div>
  );
});

const MainAgentTablePagination = ({ onAgentFilterChange }) => {
  const [agentsData, setAgentsData] = useState([]);
  const [campaigns, setCampaigns] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedAgent, setSelectedAgent] = useState('');
  const [selectedCampaign, setSelectedCampaign] = useState('');
  const [startDate, setStartDate] = useState(new Date());
  const [totalPages, setTotalPages] = useState(0);
  const [isSortingMode, setIsSortingMode] = useState(false);

  const [showEditModal, setShowEditModal] = useState(false);
  const [selectedAgentId, setSelectedAgentId] = useState(null);

  const pageSize = 25;
  const excludedAgents = [
    'Agent Viribuz',
    'Agent Irish',
    'Fad Junaid',
    'Pikachu Admin',
    'Casper MacFadden',
    'Casper McFadden',
    'RogerAdmin RobielosAdmin',
    'string string',
    'Test Agent',
    'TestX AgentX',
    'TestY AgentY'
  ];

  useEffect(() => {
    fetchCampaignsData();
    fetchAgentsData();
  }, [startDate, currentPage, selectedCampaign]);

  useEffect(() => {
    filterAgentsData();
  }, [selectedAgent, selectedCampaign]);

  const fetchCampaignsData = async () => {
    try {
      const response = await axios.get('http://statistics-staging.viribuzmedia.com/umbraco/Api/ViribuzAgentMain/GetCampaigns');
      setCampaigns(response.data.campaigns);
    } catch (error) {
      console.error('Error fetching campaigns data:', error);
    }
  };

  const fetchAgentsData = async () => {
    try {
      const formattedDate = formatDate(startDate);
      const campaignId = campaigns.find((campaign) => campaign.name === selectedCampaign)?.id;
      const response = await axios.get(
        `http://statistics-staging.viribuzmedia.com/umbraco/Api/ViribuzAgentDatabase/GetAgentsDatabase?date=${formattedDate}${campaignId ? `&campaignId=${campaignId}` : ''}`,
        { headers: { 'Accept': 'application/json' } }
      );
      console.log('Server response:', response.data);

      const filteredData = response.data.data.filter(agent => {
        const agentName = `${agent.FirstName} ${agent.LastName}`;
        return !excludedAgents.includes(agentName);
      });

      setAgentsData(filteredData);
      setTotalPages(Math.ceil(filteredData.length / pageSize));
    } catch (error) {
      console.error('Error fetching agents data:', error);
    }
  };

  const filterAgentsData = () => {
    let filteredData = agentsData;
    if (selectedAgent) {
      filteredData = agentsData.filter(
        (agent) => `${agent.FirstName} ${agent.LastName}` === selectedAgent
      );
    }
    if (selectedCampaign) {
      filteredData = filteredData.filter(
        (agent) => agent.campaign === selectedCampaign
      );
    }
    setTotalPages(Math.ceil(filteredData.length / pageSize));
  };

  const formatDate = (date) => {
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const day = date.getDate().toString().padStart(2, '0');
    return `${year}-${month}-${day}`;
  };

  const handleAgentFilterChange = (event) => {
    setSelectedAgent(event.target.value);
    onAgentFilterChange(event.target.value);
  };

  const handleCampaignFilterChange = (event) => {
    setSelectedCampaign(event.target.value);
  };

  const handleNextPage = () => {
    setCurrentPage((prevPage) => Math.min(prevPage + 1, totalPages));
  };

  const handlePrevPage = () => {
    setCurrentPage((prevPage) => Math.max(prevPage - 1, 1));
  };

  const handleSortClick = () => {
    setIsSortingMode(!isSortingMode);
  };


  const handleEditClick = (agentId) => {
    setSelectedAgentId(agentId);
    setShowEditModal(true);
  };

  const handleCloseEditModal = () => setShowEditModal(false);


  const handleDelete = (agentId) => {
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const response = await axios.delete(`http://statistics-staging.viribuzmedia.com/umbraco/Api/ViribuzAgentReport/DeleteAgentReport/${agentId}`, {
            headers: { 'Accept': 'application/json' }
          });

          if (response.data.isSuccessful) {
            Swal.fire('Deleted!', 'Your record has been deleted.', 'success');
            
            await fetchAgentsData();
          } else {
            Swal.fire('Error!', response.data.errorMessage || 'An error occurred while deleting.', 'error');
          }
        } catch (error) {
          console.error(error);
          Swal.fire('Error!', 'Failed to delete the record.', 'error');
        }
      }
    });
  };

  const renderTableRows = () => {
    const startIndex = (currentPage - 1) * pageSize;
    const endIndex = startIndex + pageSize;
    let agentsToShow = agentsData.slice(startIndex, endIndex);

    if (selectedAgent) {
      agentsToShow = agentsToShow.filter(
        (agent) => `${agent.FirstName} ${agent.LastName}` === selectedAgent
      );
    }

    return agentsToShow.map((agent, index) => (
      <tr key={index}>
        <td>{agent.Id}</td>
        <td>{agent.FirstName}</td>
        <td>{agent.LastName}</td>
        <td>{agent.TotalPhoneCallsMade}</td>
        <td>{agent.TotalMessagesSent}</td>
        <td>{agent.TotalEmailsSent}</td>
        <td>{agent.TotalContactReachOut}</td>
        <td>{agent.TotalDisqualifiedLeads}</td>
        <td>{agent.TotalAppointmentsSet}</td>
        <td>{agent.TotalRetainersAcquired}</td>
        <td>{agent.TotalDocumentsSent}</td>
        <td>{agent.TotalShowedAppointments}</td>
        <td>{agent.RetainersSet}</td>
        <td>{agent.TotalLiveTransferSent}</td>
        {/* Actions column */}
        <td>
          <img
            src={editIcon}
            alt="Edit"
            onClick={() => handleEditClick(agent.AgentId)}
            style={{
              cursor: 'pointer',
              marginRight: '5px',
              filter: 'brightness(1.2)',
              transition: 'filter 0.3s ease-in-out',
              borderRadius: '20%',
            }}
            className="retainer-icon-hover-glow"
          />
          <img
            src={delIcon}
            alt="Delete"
            style={{
              cursor: 'pointer',
              marginRight: '5px',
              filter: 'brightness(1.2)',
              transition: 'filter 0.3s ease-in-out',
              borderRadius: '20%',
            }}
            className="retainer-icon-hover-glow"
            onClick={() => handleDelete(agent.Id)}
          />
        </td>
      </tr>
    ));
  };

  return (
    <div className="container-fluid" style={{ paddingLeft: '5rem', marginTop: '2rem', marginBottom: '3rem' }}>
      {isSortingMode ? (
        <MainAgentTableSort />
      ) : (
        <Card className="border-top" style={{ borderTop: '3px solid #7b50d5', color: 'black' }}>
          <Card.Body className="d-flex justify-content-between align-items-center">
            <div className="d-flex align-items-center">
              <h3 className="m-0" style={{ color: 'black' }}>Agents</h3>
              <Form.Control
                as="select"
                onChange={handleAgentFilterChange}
                style={{ marginLeft: '1rem', width: '200px' }}
                value={selectedAgent}
              >
                <option value="">All Agents</option>
                {agentsData
                  .sort((a, b) => {
                    const nameA = `${a.FirstName} ${a.LastName}`.toUpperCase();
                    const nameB = `${b.FirstName} ${b.LastName}`.toUpperCase();
                    if (nameA < nameB) return -1;
                    if (nameA > nameB) return 1;
                    return 0;
                  })
                  .map((agent) => (
                    <option key={`${agent.FirstName}-${agent.LastName}`} value={`${agent.FirstName} ${agent.LastName}`}>
                      {`${agent.FirstName} ${agent.LastName}`}
                    </option>
                  ))}
              </Form.Control>
              <Form.Control
                as="select"
                onChange={handleCampaignFilterChange}
                style={{ marginLeft: '1rem', width: '200px' }}
                value={selectedCampaign}
              >
                <option value="">All Campaigns</option>
                {campaigns
                  .sort((a, b) => a.name.localeCompare(b.name))
                  .map((campaign) => (
                    <option key={campaign.id} value={campaign.name}>
                      {campaign.name}
                    </option>
                  ))}
              </Form.Control>
              <FontAwesomeIcon
                icon={faArrowUpZA}
                style={{ marginLeft: '1rem', fontSize: '30px', cursor: 'pointer', color: '#a2a9b2' }}
                onClick={handleSortClick}
              />
            </div>
            <div className="d-flex align-items-center ml-auto">
              <h3 className="m-0 mr-2">Date:</h3>
              <DatePicker
                customInput={<CustomInput />}
                dateFormat="MM/dd/yyyy"
                onChange={(date) => setStartDate(date)}
                placeholderText="Select Start Date"
                selected={startDate}
                popperPlacement="bottom-end"
              />
            </div>
          </Card.Body>
          <Card.Body>
            <Table striped bordered hover>
              <thead>
                <tr>
                  <th>ID</th> {/* New ID header */}
                  <th>First Name</th>
                  <th>Last Name</th>
                  <th># Phone Calls</th>
                  <th># Message Sent</th>
                  <th># Emails Sent</th>
                  <th># Contact Reach Out</th>
                  <th># DQs</th>
                  <th># Appt Sent</th>
                  <th># Retainers</th>
                  <th># Documents Sent</th>
                  <th># Showed Appt</th>
                  <th># of Retainers Set for</th>
                  <th># Live Transfers Sent</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>{renderTableRows()}</tbody>
            </Table>
            <div className="d-flex justify-content-between align-items-center">
              <div>
                Page <span id="currentPage">{currentPage}</span> of <span id="totalPages">{totalPages}</span>
              </div>
              <nav aria-label="Page navigation" className="d-flex justify-content-end">
                <ul className="pagination">
                  <li className="page-item" onClick={handlePrevPage}>
                    <button className="page-link" href="#" aria-label="Previous">
                      <span aria-hidden="true">&laquo; Previous</span>
                    </button>
                  </li>
                  <li className="page-item" onClick={handleNextPage}>
                    <button className="page-link" href="#" aria-label="Next">
                      <span aria-hidden="true">Next &raquo;</span>
                    </button>
                  </li>
                </ul>
              </nav>
            </div>
          </Card.Body>
        </Card>
      )}

      <EditButton
        agentId={selectedAgentId}
        show={showEditModal}
        handleClose={handleCloseEditModal}
        startDate={startDate}
      />
    </div>
  );
};

export default MainAgentTablePagination;
