import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Card, Table, Container, Row, Col, Button, Dropdown, DropdownButton } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSyncAlt, faMagnifyingGlass, faDatabase } from '@fortawesome/free-solid-svg-icons';
import { IoFilter } from "react-icons/io5";
import { FaRegCircle, FaRegDotCircle, FaArrowUp, FaArrowDown } from 'react-icons/fa';
import { CiCalendar } from "react-icons/ci";
import { format } from 'date-fns';
import Select from 'react-select';
import Swal from 'sweetalert2';

const priorityColors = {
  'Sr. Nurse': '#fff9ea',
  'Nurse': '#ebffea',
};

const statusColors = {
  'N/A': '#3b3b3b',
  'Survey Submitted': '#b10200',
  'Persequi Verified': '#753800',
  'Manual F/U': '#463821',
  'Persequi Alert': '#10734b',
  'Unresponsive': '#0750a5',
  'Appt Booked': '#0b53a8',
  'Appt No Show': '#583083',
  'Docs Sent': '#c6dae0',
  'Non Responsive x2': '#ffe5a0',
  'Application': '#fed0c9',
  'In Contact': '#d4edbc',
};

const assignmentColors = {
  'New Leads': '#3b3b3b',
  'Appointments': '#a50603',
  'Yesterday': '#0b53a9',
  '2 Days Ago': '#5a3386',
  '3 Days Ago': '#ffc8aa',
  '4-7 Days Old': '#ffe5a0',
  '8-31 Days Old': '#bee1f5',
  '32-60 Days Old': '#c6dae1',
  '61-90 Days Old': '#b4b7bd',
  '91+ Days Old': '#215a6d',
};

const pipelineColors = {
  'N/A': '#3b3b3b',
  'Survey Submitted': '#b10200',
  'Persequi Verified': '#753800',
  'Manual F/U': '#463821',
  'Persequi Alert': '#10734b',
  'Unresponsive': '#0750a5',
  'Appt Booked': '#0b53a8',
  'Appt No Show': '#583083',
  'Docs Sent': '#c6dae0',
  'Non Responsive x2': '#ffe5a0',
  'Application': '#fed0c9',
  'In Contact': '#d4edbc',
};

const assignmentStatusColors = {
  'In Progress': '#bfe1f5',
  'Completed': '#d3edbd',
  'Not Completed': '#ffd0c9',
};


const ScheduleDailyAssignmentAdmin = () => {
  const [assignments, setAssignments] = useState([]);
  const [selectedAssignments, setSelectedAssignments] = useState([]);
  const [agents, setAgents] = useState([]);
  const [selectedTemplate, setSelectedTemplate] = useState('Select Template');
  const [selectedTemplateId, setSelectedTemplateId] = useState(null);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [templates, setTemplates] = useState([]);
  const [campaignOptions, setCampaignOptions] = useState([]);
  const [firmOptions, setFirmOptions] = useState([]);
  const [filterStatus, setFilterStatus] = useState('View All');
  const [checkboxStates, setCheckboxStates] = useState({
    action1: false,
    action2: false,
  });

  const [status, setStatus] = useState({});

  const handleSaveChanges = () => {
    const formattedAssignments = assignments.map((assignment, index) => {
      const campaignIdStr = assignment.campaign
        .map(campaign => campaign.value)
        .filter(Boolean)
        .join(',');

      return {
        Id: assignment.id || null,
        AssignmentDate: selectedDate.toISOString().split('T')[0], 
        CampaignIdStr: campaignIdStr || '',  
        FirmId: assignment.account?.value || null,  
        Priority: assignment.priority || '',  
        AgentId: assignment.agent?.value || null,  
        ShiftType: assignment.shift || '',  
        PipelineStage: assignment.pipelineStage || '',  
        AssignmentStatus: assignment.assignmentStatus || '',  
        Assignment: assignment.assignment || '',  
        OrderNumber: index + 1,
        IsExistInServer: assignment.isExistInServer || false 
      };
    });

    const promises = formattedAssignments
      .filter(assignment => assignment.CampaignIdStr || assignment.FirmId)
      .map(assignment => {
        if (assignment.IsExistInServer === true && assignment.Id) {
          const config = {
            method: 'put',
            maxBodyLength: Infinity,
            url: 'http://statistics-staging.viribuzmedia.com/umbraco/Api/ViribuzAgentDailyAssignmentV2/PutUpdateAssignments',
            headers: { 
              'Content-Type': 'application/json', 
              'Accept': 'application/json'
            },
            data: JSON.stringify([assignment])
          };
          
          return axios.request(config)
            .then(response => {
              console.log('PUT Response:', response.data);
              return response.data;
            })
            .catch(error => {
              console.error('Error in PUT:', error);
              throw error;
            });
        } else if (assignment.IsExistInServer === false) {
          const config = {
            method: 'post',
            maxBodyLength: Infinity,
            url: 'http://statistics-staging.viribuzmedia.com/umbraco/Api/ViribuzAgentDailyAssignmentV2/PostAddAssignments',
            headers: { 
              'Content-Type': 'application/json', 
              'Accept': 'application/json'
            },
            data: JSON.stringify([assignment])
          };

          return axios.request(config)
            .then(response => {
              console.log('POST Response:', response.data);
              return response.data;
            })
            .catch(error => {
              console.error('Error in POST:', error);
              throw error;
            });
        } else {
          return Promise.resolve();
        }
      });

    Promise.all(promises)
      .then(() => {
        Swal.fire({
          icon: 'success',
          title: 'Success',
          text: 'All assignments have been saved successfully!',
          confirmButtonText: 'OK'
        });
      })
      .catch(error => {
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: 'There was an issue saving some assignments. Please try again.',
          confirmButtonText: 'OK'
        });
      });
  };

  useEffect(() => {
    const fetchTemplates = async () => {
      try {
        const response = await axios.get('http://statistics-staging.viribuzmedia.com/umbraco/Api/ViribuzAgentAssignmentTemplate/GetAssignmentTemplatesList');
        setTemplates(response.data.data);
      } catch (error) {
        console.error('Error fetching templates:', error);
      }
    };
    fetchTemplates();
  }, []);


  const addAssignment = () => {
    const newAssignment = {
      id: assignments.length + 1,
      priority: 'Nurse',
      agent: 'New Agent',
      shift: 'A.M.',
      account: 'New Account', 
      campaign: 'New Campaign', 
      assignment: 'New Leads',
      pipelineStage: 'In Contact',
      assignmentStatus: 'In Progress',
    };

    setAssignments([...assignments, newAssignment]);
  };

  const refreshTable = async () => {
    try {
      const response = await axios.get('http://statistics-staging.viribuzmedia.com/umbraco/Api/ViribuzAgentAssignmentTemplate/GetAssignmentTemplatesList');
      setTemplates(response.data.data);
      console.log('Table refreshed successfully');
    } catch (error) {
      console.error('Error refreshing table:', error);
    }
  };

  const fetchAssignments = async (templateId, selectedDate) => {
    let config;

    if (templateId) {
      config = {
        method: 'get',
        maxBodyLength: Infinity,
        url: `http://statistics-staging.viribuzmedia.com/umbraco/Api/ViribuzAgentAssignmentTemplate/GetAssignmentsList?id=${templateId}`,
        headers: { 'Accept': 'application/json' }
      };
    } else {
      const formattedDate = selectedDate.toISOString().split('T')[0];
      config = {
        method: 'get',
        maxBodyLength: Infinity,
        url: `http://statistics-staging.viribuzmedia.com/umbraco/Api/ViribuzAgentDailyAssignmentV2/GetAssignmentsByDateList?date=${formattedDate}`,
        headers: { 'Accept': 'application/json' }
      };
    }

    try {
      const response = await axios.request(config);
      const assignmentData = response.data.data;

      const mappedAssignments = assignmentData.map((item) => ({
        id: item.Id,
        priority: item.Priority,
        agent: agents.find(agent => agent.value === item.AgentId) || { value: item.AgentId, label: item.AgentName },
        shift: item.ShiftType,
        account: firmOptions.find(option => option.value === item.FirmId) || null,
        campaign: item.CampaignIdStr 
          ? campaignOptions.filter(option => item.CampaignIdStr.split(',').map(id => parseInt(id)).includes(option.value)) 
          : [],  
        assignment: item.Assignment,
        pipelineStage: item.PipelineStage,
        assignmentStatus: item.AssignmentStatus,
        orderNumber: item.OrderNumber,
        isExistInServer: item.IsExistInServer || false
      }));

      setAssignments(mappedAssignments);
    } catch (error) {
      console.error('Error fetching assignments:', error);
    }
  };

  const filteredAssignments = assignments.filter(assignment => {
    if (filterStatus === 'View All') return true;
    return assignment.assignmentStatus === filterStatus;
  });

  const handleTemplateSelect = (templateName) => {
    const selectedTemplate = templates.find((template) => template.Name === templateName);

    if (selectedTemplate) {
      setSelectedTemplate(templateName);
      setSelectedTemplateId(selectedTemplate.Id);
      fetchAssignments(selectedTemplate.Id);
    }
  };

  const deleteSelectedAssignments = async () => {
    const selectedIds = Object.keys(checkboxStates).filter(id => checkboxStates[id]);

    if (selectedIds.length === 0) {
      Swal.fire({
        icon: 'info',
        title: 'No assignments selected',
        text: 'Please select assignments to delete.'
      });
      return;
    }

    Swal.fire({
      title: 'Are you sure?',
      text: "Type 'YES' to confirm deletion.",
      input: 'text',
      inputPlaceholder: 'Type "YES" to confirm deletion.',
      showCancelButton: true,
      confirmButtonText: 'Delete',
      preConfirm: (inputValue) => {
        if (inputValue !== 'YES') {
          Swal.showValidationMessage('You need to type "YES" to confirm.');
          return false;
        }
      }
    }).then(async (result) => {
      if (result.isConfirmed && result.value === 'YES') {
        const data = JSON.stringify(selectedIds.map(id => ({ Id: Number(id) })));
        const config = {
          method: 'delete',
          maxBodyLength: Infinity,
          url: 'http://statistics-staging.viribuzmedia.com/umbraco/Api/ViribuzAgentDailyAssignmentV2/DeleteAssignments',
          headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
          },
          data: data
        };

        try {
          const response = await axios.request(config);
          if (response.data.success) {
            Swal.fire('Deleted!', 'Assignments deleted successfully!', 'success');
          } else {
            Swal.fire('Error', 'Failed to delete assignments.', 'error');
          }
        } catch (error) {
          Swal.fire('Error', 'An error occurred while deleting assignments.', 'error');
          console.error('Error deleting assignments:', error);
        }
      }
    });
  };


  useEffect(() => {
    const fetchAgents = async () => {
      try {
        const response = await axios.get('http://statistics-staging.viribuzmedia.com/umbraco/Api/ViribuzAgent/GetAgents', {
          headers: { 
            'ViribuzAccessToken': localStorage.getItem('ViribuzAccessToken') 
          }
        });
        const agentOptions = response.data.data.map(agent => ({
          value: agent.id,
          label: `${agent.firstName} ${agent.lastName}`
        }));
        setAgents(agentOptions);
      } catch (error) {
        console.error('Error fetching agents:', error);
      }
    };

    fetchAgents();
  }, []);

  const handleAgentChange = (assignmentId, selectedAgent) => {
    setAssignments(assignments.map(assignment =>
      assignment.id === assignmentId ? { ...assignment, agent: selectedAgent } : assignment
    ));
  };


  const handleShiftChange = (id, newShift) => {
    setAssignments(assignments.map(assignment =>
      assignment.id === id ? { ...assignment, shift: newShift } : assignment
    ));
  };

  useEffect(() => {
    const fetchCampaigns = async () => {
      try {
        const response = await axios.get('http://statistics-staging.viribuzmedia.com/umbraco/Api/ViribuzAgentMain/GetCampaigns');
        const campaignData = response.data.campaigns;
        const options = campaignData.map((campaign) => ({
          value: campaign.id,
          label: campaign.name,
        }));
        setCampaignOptions(options);
      } catch (error) {
        console.error('Error fetching campaigns:', error);
      }
    };
    fetchCampaigns();
  }, []);

  const handleCampaignChange = (assignmentId, selectedOptions) => {
    setAssignments(prevAssignments =>
      prevAssignments.map(assignment =>
        assignment.id === assignmentId
          ? { ...assignment, campaign: selectedOptions }
          : assignment
      )
    );
  };

  useEffect(() => {
    const fetchFirms = async () => {
      try {
        const response = await axios.get('http://statistics-staging.viribuzmedia.com/umbraco/Api/ViribuzAgentMain/GetFirms');
        const firms = response.data.firms.map(firm => ({
          value: firm.id,
          label: firm.name
        }));
        setFirmOptions(firms);
      } catch (error) {
        console.error('Error fetching firms:', error);
      }
    };
    fetchFirms();
  }, []);

  const handleAccountChange = (assignmentId, selectedOption) => {
    setAssignments(prevAssignments =>
      prevAssignments.map(assignment =>
        assignment.id === assignmentId
          ? { ...assignment, account: selectedOption } 
          : assignment
      )
    );
  };

  const handlePriorityChange = (id, newPriority) => {
    setAssignments(assignments.map(assignment =>
      assignment.id === id ? { ...assignment, priority: newPriority } : assignment
    ));
  };

  const handleStatusChange = (assignmentId, newStatus) => {
    setAssignments((prevAssignments) =>
      prevAssignments.map((assignment) =>
        assignment.id === assignmentId
          ? { ...assignment, assignmentStatus: newStatus }
          : assignment
      )
    );
  };

  const handlePipelineStageChange = (id, newPipelineStage) => {
    setAssignments(assignments.map(assignment =>
      assignment.id === id ? { ...assignment, pipelineStage: newPipelineStage } : assignment
    ));
  };


  const handleAssignmentChange = (id, newAssignment) => {
    setAssignments(prevAssignments => prevAssignments.map(assignment =>
      assignment.id === id ? { ...assignment, assignment: newAssignment } : assignment
    ));
  };

  const toggleCheckbox = (assignmentId) => {
    setCheckboxStates(prevState => ({
      ...prevState,
      [assignmentId]: !prevState[assignmentId],
    }));
  };

  const dayOfWeek = format(selectedDate, 'EEEE');

  const iconStyle = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: '1.5rem',
    cursor: 'pointer',
  };

  const moveRow = (index, direction) => {
    const newAssignments = [...assignments];
    
    if (direction === 'up' && index > 0) {
      [newAssignments[index], newAssignments[index - 1]] = [newAssignments[index - 1], newAssignments[index]];
    } else if (direction === 'down' && index < newAssignments.length - 1) {
      [newAssignments[index], newAssignments[index + 1]] = [newAssignments[index + 1], newAssignments[index]];
    }
    
    setAssignments(newAssignments);
  };


  return (
    <Container fluid style={{ paddingLeft: '5rem', marginTop: '2rem', marginBottom: '3rem' }}>
      <style>
        {`
          .custom-datepicker {
            border: none !important;
            box-shadow: none !important;
            text-align: center;
          }
          .datepicker-container {
            display: flex;
            align-items: center;
            justify-content: center;
          }
          .datepicker-container .react-datepicker-wrapper {
            width: 140px; 
          }
        `}
      </style>
      <Row className="justify-content-center">
        <div className="datepicker-container" style={{ border: '1px solid #cbcbcb', borderRadius: '10px', marginLeft: '1rem', padding: '5px', fontWeight: 'bold' }}>
          <CiCalendar style={{ fontSize: '1.5rem', marginRight: '-1rem' }} />
          <DatePicker
            selected={selectedDate}
            onChange={date => {
              setSelectedDate(date); 
              fetchAssignments(null, date);
            }}
            dateFormat="MMMM d, yyyy"
            className="form-control custom-datepicker"
            popperPlacement="bottom-start"
            style={{ margin: '1px' }}
          />
          <span style={{ marginLeft: '-1rem' }}>- {dayOfWeek}</span>
        </div> 
        <Col md={12}>
          <Card style={{ marginTop: '2rem', borderTop: '3px solid #7b50d5', color: 'black' }}>
            <Card.Body style={{ paddingLeft: '0', paddingRight: '0' }}>
              <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <div style={{ display: 'flex', alignItems: 'center', marginLeft: '1rem' }}>
                  <h3 style={{ fontWeight: 'bold' }}>Daily Assignments</h3>
                </div>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <Button 
                    variant="secondary" 
                    style={{ border: '1px solid #ccc', padding: '15px', marginRight: '0.5rem', borderRadius: '10px', display: 'flex', alignItems: 'center' }}
                    onClick={refreshTable}
                  >
                    <FontAwesomeIcon icon={faSyncAlt} />
                  </Button>
                  <DropdownButton
                    variant="success"
                    id="dropdown-basic-button"
                    title={selectedTemplate}
                    onSelect={handleTemplateSelect}
                    style={{ marginRight: '5px', border: '1px solid #cbcbcb', borderRadius: '10px' }}
                  >
                    <Dropdown.Item
                      eventKey="Select Template"
                      style={{ color: '#6c757d' }}
                    >
                      Select Template
                    </Dropdown.Item>
                    {/* Dynamically render templates from API */}
                    {templates.map(template => (
                      <Dropdown.Item 
                        key={template.Id} 
                        eventKey={template.Name} 
                        onClick={() => handleTemplateSelect(template.Name)}
                      >
                        {template.Name}
                      </Dropdown.Item>
                    ))}
                  </DropdownButton>
                  <Button 
                    variant="danger" 
                    style={{ marginRight: '1rem', backgroundColor: 'white', border: '1px solid #cbcbcb', borderRadius: '10px', margin: '2px' }}
                    onClick={deleteSelectedAssignments}
                  >
                    Delete Assignment
                  </Button>
                  <Button 
                    variant="primary" 
                    style={{ backgroundColor: '#6841c7', borderRadius: '10px', margin: '2px', marginRight: '1rem' }} 
                    onClick={addAssignment}
                  >
                    + Add Assignment
                  </Button>
                </div>
              </div>

              <hr style={{ width: '100%', margin: '1rem 0', border: '0.5px solid #ced2d6' }} />

              <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '1rem' }}>
                <div style={{ display: 'flex', alignItems: 'center', border: '1px solid #ccc', borderRadius: '10px', marginLeft: '1rem' }}>
                  <Button
                    variant="outline-primary"
                    style={{
                      borderRadius: '10px',
                      boxShadow: filterStatus === 'View All' ? '0px 0px 15px rgba(0, 123, 255, 0.6)' : 'none'
                    }}
                    onClick={() => setFilterStatus('View All')}
                  >
                    View All
                  </Button>
                  <div style={{ height: '40px', width: '1px', backgroundColor: '#ccc' }} />
                  
                  <Button
                    variant="outline-success"
                    style={{
                      borderRadius: '10px',
                      boxShadow: filterStatus === 'Completed' ? '0px 0px 15px rgba(40, 167, 69, 0.6)' : 'none'
                    }}
                    onClick={() => setFilterStatus('Completed')}
                  >
                    Completed
                  </Button>
                  <div style={{ height: '40px', width: '1px', backgroundColor: '#ccc' }} />
                  
                  <Button
                    variant="outline-warning"
                    style={{
                      borderRadius: '10px',
                      boxShadow: filterStatus === 'In Progress' ? '0px 0px 15px rgba(255, 193, 7, 0.6)' : 'none'
                    }}
                    onClick={() => setFilterStatus('In Progress')}
                  >
                    In Progress
                  </Button>
                </div>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <div style={{ border: '1px solid #ccc', padding: '5px', marginRight: '0.5rem', borderRadius: '5px', display: 'flex', alignItems: 'center' }}>
                    <FontAwesomeIcon icon={faMagnifyingGlass} style={{ marginRight: '0.5rem', color: '#a2a9b2' }} />
                    <input type="text" placeholder="Search" style={{ border: 'none', outline: 'none' }} />
                  </div>
                  <Button variant="outline-secondary" style={{ border: '1px solid #ccc', padding: '5px', marginRight: '1rem', borderRadius: '5px', display: 'flex', alignItems: 'center' }}>
                    <IoFilter size="1.5em" />
                    <span style={{ paddingLeft: '5px' }}>Filters</span>
                  </Button>
                </div>
              </div>

              <Table striped bordered hover responsive>
                <thead>
                  <tr>
                    <th>ID</th>
                    <th>Priority</th>
                    <th>Agent</th>
                    <th>Shift</th>
                    <th>Account</th>
                    <th>Campaign</th>
                    <th>Assignment</th>
                    <th>Pipeline Stage</th>
                    <th>Assignment Status</th>
                    <th>Select Assignment</th>
                    <th>Move</th>
                    <th style={{ display: 'none' }}>From Server</th>
                  </tr>
                </thead>
                <tbody>
                  {filteredAssignments.map((assignment, index) => (
                    <tr key={assignment.id}>
                      <td>{assignment.id}</td>
                      <td>
                        <Dropdown>
                          <Dropdown.Toggle 
                            variant="success" 
                            id="dropdown-basic" 
                            style={{
                              backgroundColor: priorityColors[assignment.priority] || '#d4edda',
                              borderRadius: '12px',
                              padding: '0.5rem 1rem',
                              fontSize: '0.7rem',
                              border: 'none',
                              boxShadow: 'none',
                              margin: '0.25rem',
                              display: 'inline-block',
                              textAlign: 'center',
                              color: '#000',
                            }}
                          >
                            {assignment.priority}
                          </Dropdown.Toggle>

                          <Dropdown.Menu 
                            style={{
                              maxHeight: '200px',
                              overflowY: 'auto',
                            }}
                          >
                            {Object.keys(priorityColors).map((priorityKey) => (
                              <Dropdown.Item 
                                key={priorityKey}
                                onClick={() => handlePriorityChange(assignment.id, priorityKey)}
                                style={{ backgroundColor: priorityColors[priorityKey], color: '#000' }}
                              >
                                {priorityKey}
                              </Dropdown.Item>
                            ))}
                          </Dropdown.Menu>
                        </Dropdown>
                      </td>

                      <td>
                        <Select
                          options={agents} 
                          value={assignment.agent}
                          onChange={(selectedAgent) => handleAgentChange(assignment.id, selectedAgent)}
                          placeholder="Select agent"
                          styles={{
                            control: (base) => ({
                              ...base,
                              borderRadius: '10px',
                              borderColor: '#ccc',
                            }),
                          }}
                        />
                      </td>

                      <td>
                        <Select
                          value={{ value: assignment.shift, label: assignment.shift }}
                          onChange={(selectedOption) => handleShiftChange(assignment.id, selectedOption.value)}
                          options={[
                            { value: 'A.M.', label: 'A.M.' },
                            { value: 'P.M.', label: 'P.M.' }
                          ]}
                          styles={{
                            control: (base) => ({
                              ...base,
                              minHeight: '34px',
                              fontSize: '0.875rem',
                            }),
                          }}
                        />
                      </td>

                      <td>
                        <Select
                          value={firmOptions.find(option => option.value === assignment.account?.value)}
                          onChange={(selectedOption) => handleAccountChange(assignment.id, selectedOption)}
                          options={firmOptions}
                        />
                      </td>
                      
                      <td>
                        <Select
                          isMulti
                          value={campaignOptions.filter(option => Array.isArray(assignment.campaign) && assignment.campaign.some(c => c.value === option.value))}
                          onChange={(selectedOptions) => handleCampaignChange(assignment.id, selectedOptions)}
                          options={campaignOptions}
                        />
                      </td>

                      <td>
                        <Dropdown>
                          <Dropdown.Toggle 
                            variant="success" 
                            id="dropdown-basic" 
                            style={{
                              backgroundColor: assignmentColors[assignment.assignment] || '#d4edda',
                              borderRadius: '12px',
                              padding: '0.5rem 1rem',
                              fontSize: '0.7rem',
                              border: 'none',
                              boxShadow: 'none',
                              margin: '0.25rem',
                              display: 'inline-block',
                              textAlign: 'center',
                              color: '#fff',
                            }}
                          >
                            {assignment.assignment}
                          </Dropdown.Toggle>
                          <Dropdown.Menu 
                            style={{
                              maxHeight: '200px',
                              overflowY: 'auto',
                            }}
                          >
                            {Object.keys(assignmentColors).map((assignmentKey) => (
                              <Dropdown.Item 
                                key={assignmentKey}
                                onClick={() => handleAssignmentChange(assignment.id, assignmentKey)}
                                style={{ backgroundColor: assignmentColors[assignmentKey], color: '#fff' }}
                              >
                                {assignmentKey}
                              </Dropdown.Item>
                            ))}
                          </Dropdown.Menu>
                        </Dropdown>
                      </td>

                      <td>
                        <Dropdown>
                          <Dropdown.Toggle 
                            variant="success" 
                            id="dropdown-basic" 
                            style={{
                              backgroundColor: pipelineColors[assignment.pipelineStage] || '#d4edda',
                              borderRadius: '12px',
                              padding: '0.5rem 1rem',
                              fontSize: '0.7rem',
                              border: 'none',
                              boxShadow: 'none',
                              margin: '0.25rem',
                              display: 'inline-block',
                              textAlign: 'center',
                              color: '#fff',
                            }}
                          >
                            {assignment.pipelineStage} 
                          </Dropdown.Toggle>
                          <Dropdown.Menu 
                            style={{
                              maxHeight: '200px',
                              overflowY: 'auto',
                            }}
                          >
                            {Object.keys(pipelineColors).map((pipelineKey) => (
                              <Dropdown.Item 
                                key={pipelineKey}
                                onClick={() => handlePipelineStageChange(assignment.id, pipelineKey)}
                                style={{ backgroundColor: pipelineColors[pipelineKey], color: '#fff' }}
                              >
                                {pipelineKey}
                              </Dropdown.Item>
                            ))}
                          </Dropdown.Menu>
                        </Dropdown>
                      </td>

                      <td>
                        <Dropdown>
                          <Dropdown.Toggle
                            id="dropdown-basic"
                            style={{
                              backgroundColor: assignmentStatusColors[assignment.assignmentStatus] || '#d4edda',
                              borderRadius: '12px',
                              padding: '0.5rem 1rem',
                              fontSize: '0.7rem',
                              border: 'none',
                              margin: '0.25rem',
                              color: '#fff',
                            }}
                          >
                            {assignment.assignmentStatus}
                          </Dropdown.Toggle>
                          <Dropdown.Menu style={{ maxHeight: '200px', overflowY: 'auto' }}>
                            {Object.keys(assignmentStatusColors).map((statusKey) => (
                              <Dropdown.Item
                                key={statusKey}
                                onClick={() => handleStatusChange(assignment.id, statusKey)}
                                style={{
                                  backgroundColor: assignmentStatusColors[statusKey],
                                  color: '#fff',
                                }}
                              >
                                {statusKey}
                              </Dropdown.Item>
                            ))}
                          </Dropdown.Menu>
                        </Dropdown>
                      </td>

                      <td>
                        <div style={iconStyle} onClick={() => toggleCheckbox(assignment.id)}>
                          {checkboxStates[assignment.id] ? <FaRegDotCircle style={{ color: '#de01ff' }} /> : <FaRegCircle style={{ color: '#a2a9b2' }} />}
                        </div>
                      </td>

                      <td>
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '10px' }}>
                          <button
                            onClick={() => moveRow(index, 'up')}
                            disabled={index === 0}
                            style={{
                              border: 'none',
                              background: 'none',
                              cursor: index === 0 ? 'not-allowed' : 'pointer',
                              padding: '5px',
                              transition: 'transform 0.2s',
                            }}
                            onMouseEnter={(e) => e.currentTarget.style.transform = 'scale(1.2)'}
                            onMouseLeave={(e) => e.currentTarget.style.transform = 'scale(1)'}
                          >
                            <FaArrowUp size="1em" color={index === 0 ? '#ccc' : '#000'} />
                          </button>
                          <button
                            onClick={() => moveRow(index, 'down')}
                            disabled={index === assignments.length - 1}
                            style={{
                              border: 'none',
                              background: 'none',
                              cursor: index === assignments.length - 1 ? 'not-allowed' : 'pointer',
                              padding: '5px',
                              transition: 'transform 0.2s',
                            }}
                            onMouseEnter={(e) => e.currentTarget.style.transform = 'scale(1.2)'}
                            onMouseLeave={(e) => e.currentTarget.style.transform = 'scale(1)'}
                          >
                            <FaArrowDown size="1em" color={index === assignments.length - 1 ? '#ccc' : '#000'} />
                          </button>
                        </div>
                      </td>

                      <td style={{ display: 'none' }}>
                        {assignment.isExistInServer && (
                          <FontAwesomeIcon icon={faDatabase} style={{ color: '#4caf50' }} />
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>


              <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '1rem' }}>
                <Button variant="secondary" style={{ marginRight: '1rem', backgroundColor: 'white', border: '1px solid #cbcbcb', borderRadius: '10px', margin: '2px' }}>Cancel</Button>
                <Button
                  variant="primary"
                  style={{ backgroundColor: '#6841c7', borderRadius: '10px', margin: '2px', marginRight: '1rem' }}
                  onClick={handleSaveChanges}
                >
                  Save Changes
                </Button>
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default ScheduleDailyAssignmentAdmin;
