import React, { useState, useEffect } from 'react';
import '@fortawesome/fontawesome-free/css/all.min.css';
import { Modal, Button, Form, Dropdown } from 'react-bootstrap';
import editIcon from '../../../assets/icons/edit.png';
import delIcon from '../../../assets/icons/delete.png';
import axios from 'axios';
import { format } from 'date-fns';
import Swal from 'sweetalert2';
import EditButton from '../EditButton.js';

import '../retainertracker.css';

const NECTable = () => {
  const [modalVisible, setModalVisible] = useState(false);
  const [campaignOptions, setCampaignOptions] = useState([]);
  const [lawFirmOptions, setLawFirmOptions] = useState([]);
  const medicalRecordOptions = ['YES', 'NO', 'N/A'];
  const retainerPushedOptions = ['YES', 'NO', 'N/A', 'PAUSED'];
  const [tableData, setTableData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = 5;

  useEffect(() => {
    fetchCampaigns();
    fetchLawFirms();
    fetchData(currentPage);
    const intervalId = setInterval(() => fetchData(currentPage), 10000);
    return () => clearInterval(intervalId);
  }, [currentPage]);

  const fetchCampaigns = async () => {
    try {
      const response = await axios.get('http://statistics-staging.viribuzmedia.com/umbraco/Api/ViribuzAgentMain/GetCampaigns');
      const { campaigns } = response.data;
      const sortedCampaigns = campaigns.sort((a, b) => a.name.localeCompare(b.name));
      setCampaignOptions(sortedCampaigns);
    } catch (error) {
      console.error("Error fetching campaigns:", error);
    }
  };

  const fetchLawFirms = async () => {
    try {
      const response = await axios.get('http://statistics-staging.viribuzmedia.com/umbraco/Api/ViribuzAgentMain/GetFirms');
      const { firms } = response.data;
      const sortedFirms = firms.sort((a, b) => a.name.localeCompare(b.name));
      setLawFirmOptions(sortedFirms);
    } catch (error) {
      console.error("Error fetching firms:", error);
    }
  };

  const fetchData = async (page) => {
    try {
      const response = await axios.get(`http://statistics-staging.viribuzmedia.com/umbraco/Api/ViribuzAgentRetainer/GetRetainers?page=${page}&pageSize=${pageSize}`, {
        headers: {
          'Accept': 'application/json'
        }
      });
      if (response.data && response.data.data) {
        const filteredData = response.data.data.filter(item => item.CampaignId === 2190);
        const sortedData = filteredData.sort((a, b) => new Date(b.DateRetained) - new Date(a.DateRetained));
        setTableData(sortedData);
      }
    } catch (error) {
      console.error('Error fetching retainer data:', error);
    }
  };

  const [formData, setFormData] = useState({
    id: '',
    DateRetained: '',
    CampaignId: '',
    LawFirmId: '',
    RetainerLT: '',
    FirstName: '',
    LastName: '',
    RetainerMethod: '',
    SystemUsed: '',
    MedicalRecord: '',
    RetainerPushed: '',
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const formatDate = (date) => {
    const formattedDate = new Date(date);
    return format(formattedDate, 'MM/dd/yyyy');
  };


  const submitForm = () => {
    const id = localStorage.getItem('id');
    const updatedFormData = { ...formData, id };
    axios.post('http://statistics-staging.viribuzmedia.com/umbraco/Api/ViribuzAgentRetainer/PostAddNewRetainer', updatedFormData)
      .then(response => {
        console.log('Data sent to server:', updatedFormData);
        Swal.fire({
          icon: 'success',
          title: 'Success',
          text: 'Submission successful!',
        });
        setFormData({
          id: '',
          DateRetained: '',
          CampaignId: '',
          LawFirmId: '',
          RetainerLT: '',
          FirstName: '',
          LastName: '',
          RetainerMethod: '',
          SystemUsed: '',
          MedicalRecord: '',
          RetainerPushed: '',
        });
        closeModal();
      })
      .catch(error => {
        console.error('Error submitting data:', error);
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: error.response.data.message || 'Something went wrong!',
        });
      });
  };

  const onDeleteClick = async (Id) => {
    try {
      await axios.delete(`http://statistics-staging.viribuzmedia.com/umbraco/Api/ViribuzAgentRetainer/DeleteRetainer/${Id}`, {
        headers: {
          'Accept': 'application/json'
        }
      });
      fetchData();
    } catch (error) {
      console.error('Error deleting retainer:', error);
    }
  };

  const openModal = () => {
    setModalVisible(true);
  };
  const closeModal = () => {
    setModalVisible(false);
    setFormData({
      DateRetained: '',
      CampaignId: '',
      LawFirmId: '',
      RetainerLT: '',
      FirstName: '',
      LastName: '',
      RetainerMethod: '',
      SystemUsed: '',
      MedicalRecord: '',
      RetainerPushed: '',
    });
  };

  const totalPages = Math.ceil(tableData.length / pageSize);

  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const renderTableRows = () => {
    const startIndex = (currentPage - 1) * pageSize;
    const endIndex = startIndex + pageSize;

    return tableData.slice(startIndex, endIndex).map((rowData, rowIndex) => (
      <tr key={rowIndex}>
        <td>{formatDate(rowData.DateRetained)}</td>
        <td>{getCampaignName(rowData.CampaignId)}</td>
        <td>{getLawFirmName(rowData.LawFirmId)}</td>
        <td>{rowData.RetainerLT}</td>
        <td>{rowData.FirstName} {rowData.LastName}</td>
        <td>{rowData.Turnback}</td>
        <td>{formatDate(rowData.TurnbackDate)}</td>
        <td>{rowData.TurnbackReason}</td>
        <td>{rowData.RetainerMethod}</td>
        <td>{rowData.SystemUsed}</td>
        <td>{rowData.MedicalRecord}</td>
        <td>{rowData.RetainerPushed}</td>
        <td>
          {/* Icons for edit and delete */}
          <EditButton
            rowData={rowData}
            fetchData={fetchData}
            currentPage={currentPage}
            medicalRecordOptions={medicalRecordOptions}
            retainerPushedOptions={retainerPushedOptions}
          />
          <img
            src={delIcon}
            alt="Delete"
            onClick={() => onDeleteClick(rowData.Id)}
            style={{
              cursor: 'pointer',
              marginRight: '5px',
              filter: 'brightness(1.2)',
              transition: 'filter 0.3s ease-in-out',
              borderRadius: '20%',
            }}
            className="retainer-icon-hover-glow"
          />
        </td>
      </tr>
    ));
  };

  const getCampaignName = (campaignId) => {
    const campaign = campaignOptions.find(campaign => campaign.id === campaignId);
    return campaign ? campaign.name : '';
  };

  const getLawFirmName = (lawFirmId) => {
    const lawFirm = lawFirmOptions.find(firm => firm.id === lawFirmId);
    return lawFirm ? lawFirm.name : '';
  };

  return (
    <div className="container-fluid" style={{ paddingLeft: '5rem', marginTop: '2rem', marginBottom: '3rem' }}>
      <div className="card bg-white card-with-box-shadow">
        <div className="card-body">
          <h3 className="card-title d-flex justify-content-between align-items-center" style={{ color: 'black' }}>
            NEC
            <div>
              <button
                className="btn"
                style={{
                  cursor: 'pointer',
                  backgroundColor: 'white',
                  fontSize: '14px',
                  padding: '5px 10px',
                  borderRadius: '4px',
                  border: '1px solid #ccc',
                }}
                onClick={openModal}
              >
                Add Lead +
              </button>
            </div>
          </h3>
          <div className="scrollable-table" style={{ maxHeight: '450px', overflowY: 'auto' }}>
            <table className="table">
              <thead className="thead-fixed">
                <tr>
                  <th scope="col">Date Retained</th>
                  <th scope="col">Campaign</th>
                  <th scope="col">Law Firm</th>
                  <th scope="col">Retainer/LT</th>
                  <th scope="col">Lead Name</th>
                  <th scope="col">Turnback</th>
                  <th scope="col">Turnback Date</th>
                  <th scope="col">Turnback Reason</th>
                  <th scope="col">Retainer Method</th>
                  <th scope="col">System Used</th>
                  <th scope="col">Medical Record</th>
                  <th scope="col">Retainer Pushed</th>
                  <th scope="col"></th>
                </tr>
              </thead>
              <tbody>
                {renderTableRows()}
              </tbody>
            </table>
            {/* Pagination */}
            <div className="d-flex justify-content-between align-items-center">
              <div>
                Page <span id="currentPage">{currentPage}</span> of <span id="totalPages">{totalPages}</span>
              </div>
              <nav aria-label="Page navigation" className="d-flex justify-content-end">
                <ul className="pagination">
                  <li className="page-item" onClick={handlePrevPage}>
                    <button className="page-link" href="#" aria-label="Previous">
                      <span aria-hidden="true">&laquo; Previous</span>
                    </button>
                  </li>
                  <li className="page-item" onClick={handleNextPage}>
                    <button className="page-link" href="#" aria-label="Next">
                      <span aria-hidden="true">Next &raquo;</span>
                    </button>
                  </li>
                </ul>
              </nav>
            </div>
          </div>
        </div>
      </div>

      <Modal show={modalVisible} onHide={closeModal}>
        <Modal.Header closeButton>
          <Modal.Title style={{ color: 'black', fontWeight: 'bold' }}>NEC</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId="formDateRetained">
              <Form.Label>Date Retained:</Form.Label>
              <Form.Control
                type="date"
                name="DateRetained"
                value={formData.DateRetained}
                onChange={handleChange}
              />
            </Form.Group>
            <Form.Group controlId="formCampaignId">
              <Form.Label>Campaign</Form.Label>
              <Form.Control
                as="select"
                name="CampaignId"
                value={formData.CampaignId}
                onChange={handleChange}
              >
                <option value="">Select a Campaign</option>
                {campaignOptions.map((campaign) => (
                  <option key={campaign.id} value={campaign.id}>
                    {campaign.name}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>

            <div className="row mb-3">
              <div className="col-6">
                <Form.Group controlId="formLawFirmId">
                  <Form.Label>Law Firm</Form.Label>
                  <Form.Control
                    as="select"
                    name="LawFirmId"
                    value={formData.LawFirmId}
                    onChange={handleChange}
                  >
                    <option value="">Select a Law Firm</option>
                    {lawFirmOptions.map((firm) => (
                      <option key={firm.id} value={firm.id}>
                        {firm.name}
                      </option>
                    ))}
                  </Form.Control>
                </Form.Group>
              </div>
              <div className="col-6">
                <Form.Group controlId="formRetainer">
                  <Form.Label>Retainer/LT:</Form.Label>
                  <Dropdown onSelect={(selectedOption) => setFormData((prevData) => ({ ...prevData, RetainerLT: selectedOption }))}>
                    <Dropdown.Toggle variant="success" id="dropdown-basic" style={{ width: '100%', border: '1px solid #cbcbcb' }}>
                      {formData.RetainerLT || 'Select Retainer/LT'}
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      {medicalRecordOptions.map((option) => (
                        <Dropdown.Item key={option} eventKey={option}>
                          {option}
                        </Dropdown.Item>
                      ))}
                    </Dropdown.Menu>
                  </Dropdown>
                </Form.Group>
              </div>
            </div>

            <Form.Group controlId="formFirstName">
              <Form.Label>First Name:</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter First Name"
                name="FirstName"
                value={formData.FirstName}
                onChange={handleChange}
              />
            </Form.Group>
            <Form.Group controlId="formLastName">
              <Form.Label>Last Name:</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter Last Name"
                name="LastName"
                value={formData.LastName}
                onChange={handleChange}
              />
            </Form.Group>

            <Form.Group controlId="formRetainerMethod">
              <Form.Label>Retainer Method:</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter Retainer Method"
                name="RetainerMethod"
                value={formData.RetainerMethod}
                onChange={handleChange}
              />
            </Form.Group>

            <Form.Group controlId="formSystemUsed">
              <Form.Label>System Used:</Form.Label>
              <Form.Control
                as="select"
                name="SystemUsed"
                value={formData.SystemUsed}
                onChange={handleChange}
              >
                <option value="">Select Option</option>
                <option value="GHL">GHL</option>
                <option value="Open Phone">Open Phone</option>
                <option value="Dialer">Dialer</option>
                <option value="Personal Phone">Personal Phone</option>
              </Form.Control>
            </Form.Group>

            <div className="row mb-3">
              <div className="col-6">
                <Form.Group controlId="formMedicalRecord">
                  <Form.Label>Medical Record:</Form.Label>
                  <Dropdown onSelect={(selectedOption) => setFormData((prevData) => ({ ...prevData, MedicalRecord: selectedOption }))}>
                    <Dropdown.Toggle variant="success" id="dropdown-basic" style={{ width: '100%', border: '1px solid #cbcbcb' }}>
                      {formData.MedicalRecord || 'Select Option'}
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      {medicalRecordOptions.map((option) => (
                        <Dropdown.Item key={option} eventKey={option}>
                          {option}
                        </Dropdown.Item>
                      ))}
                    </Dropdown.Menu>
                  </Dropdown>
                </Form.Group>
              </div>
              <div className="col-6">
                <Form.Group controlId="formRetainerPushed">
                  <Form.Label>Retainer Pushed:</Form.Label>
                  <Dropdown onSelect={(selectedOption) => setFormData((prevData) => ({ ...prevData, RetainerPushed: selectedOption }))}>
                    <Dropdown.Toggle variant="success" id="dropdown-basic" style={{ width: '100%', border: '1px solid #cbcbcb' }}>
                      {formData.RetainerPushed || 'Select Option'}
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      {retainerPushedOptions.map((option) => (
                        <Dropdown.Item key={option} eventKey={option}>
                          {option}
                        </Dropdown.Item>
                      ))}
                    </Dropdown.Menu>
                  </Dropdown>
                </Form.Group>
              </div>
            </div>
          </Form>
        </Modal.Body>
        <Modal.Footer style={{ marginBottom: '1rem', marginRight: '1rem' }}>
          <Button
            variant="secondary"
            onClick={closeModal}
            style={{ border: '1px solid #cbcbcb' }}
          >
            Cancel
          </Button>
          <Button
            variant="primary"
            onClick={submitForm}
            style={{ backgroundColor: '#7F56D9', borderColor: '#7F56D9', color: 'white' }}
          >
            Submit
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default NECTable;
