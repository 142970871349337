import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Modal, Button, Table, Form } from 'react-bootstrap';
import styled from 'styled-components';
import editIcon from '../../assets/icons/edit.png';
import delIcon from '../../assets/icons/delete.png';
import Swal from 'sweetalert2';

const CustomModal = styled(Modal)`
  .modal-dialog {
    max-width: 90vw !important;
    width: 90vw !important;
  }
`;

const EditButton = ({ agentId, show, handleClose, startDate }) => {
  const [agentData, setAgentData] = useState([]);
  const [showEditModal, setShowEditModal] = useState(false);
  const [editData, setEditData] = useState({});

  useEffect(() => {
    if (show && agentId) {
      fetchAgentData();
    }
  }, [show, agentId]);

  const fetchAgentData = async () => {
    try {
      const formattedDate = startDate ? startDate.toISOString().split('T')[0] : null;
      const response = await axios.get(`http://statistics-staging.viribuzmedia.com/umbraco/Api/ViribuzAgentDatabase/GetAgentsDatabaseDetails?agentId=${agentId}&dateRetained=${formattedDate}`, {
        headers: { Accept: 'application/json' },
      });
      setAgentData(response.data.data || []);
    } catch (error) {
      console.error('Error fetching agent data:', error);
    }
  };

  const handleDeleteClick = async (id) => {
    Swal.fire({
      title: 'Are you sure?',
      text: 'This action will delete the agent data permanently!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const response = await axios.delete(`http://statistics-staging.viribuzmedia.com/umbraco/Api/ViribuzAgentReport/DeleteAgentReport/${id}`, {
            headers: { Accept: 'application/json' }
          });
          if (response.data.isSuccessful) {
            Swal.fire(
              'Deleted!',
              'The agent data has been deleted.',
              'success'
            );
            fetchAgentData();
          }
        } catch (error) {
          console.error('Error deleting agent data:', error);
          Swal.fire(
            'Error!',
            'There was a problem deleting the agent data.',
            'error'
          );
        }
      }
    });
  };

  const handleEditClick = (data) => {
    setEditData(data);
    setShowEditModal(true);
  };

  const handleEditChange = (e) => {
    const { name, value } = e.target;
    setEditData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleSaveChanges = async () => {
    const payload = {
      ...editData,
      FirmIdsStr: editData.FirmIdStr,
      CampaignIdsStr: editData.CampaignIdsStr,
      DateRetained: startDate ? startDate.toISOString().split('T')[0] : null,
      ShiftManagerId: 0,
      TotalWorkedHours: "0"
    };

    try {
      const response = await axios.put('http://statistics-staging.viribuzmedia.com/umbraco/Api/ViribuzAgentReport/PutUpdateAgentReport', payload, {
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      });

      if (response.data.success) {
        Swal.fire({
          title: 'Success!',
          text: 'Data updated successfully.',
          icon: 'success',
          confirmButtonText: 'OK'
        });
        fetchAgentData();
      } else {
        Swal.fire({
          title: 'Update Failed',
          text: 'Data was not updated. Please try again later.',
          icon: 'error',
          confirmButtonText: 'OK'
        });
      }
    } catch (error) {
      console.error('Error updating agent data:', error);
      Swal.fire({
        title: 'Error',
        text: 'An error occurred while updating data. Please try again later.',
        icon: 'error',
        confirmButtonText: 'OK'
      });
    }
    setShowEditModal(false);
  };


  return (
    <>
      <CustomModal show={show} onHide={handleClose} centered size="xl">
        <Modal.Header closeButton>
          <Modal.Title>Agent Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {agentData.length > 0 ? (
            <div style={{ maxWidth: '100%', overflowX: 'auto' }}>
              <Table striped bordered hover>
                <thead>
                  <tr>
                    <th>ID</th>
                    <th>First Name</th>
                    <th>Last Name</th>
                    <th># Phone Calls</th>
                    <th># Message Sent</th>
                    <th># Emails Sent</th>
                    <th># Contact Reach Out</th>
                    <th># DQs</th>
                    <th># Appt Sent</th>
                    <th># Retainers</th>
                    <th># Documents Sent</th>
                    <th># Showed Appt</th>
                    <th># of Retainers Set for</th>
                    <th># Live Transfers Sent</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {agentData.map((data, index) => (
                    <tr key={index}>
                      <td>{data.Id}</td>
                      <td>{data.FirstName}</td>
                      <td>{data.LastName}</td>
                      <td>{data.TotalPhoneCallsMade}</td>
                      <td>{data.TotalMessagesSent}</td>
                      <td>{data.TotalEmailsSent}</td>
                      <td>{data.TotalContactReachOut}</td>
                      <td>{data.TotalDisqualifiedLeads}</td>
                      <td>{data.TotalAppointmentsSet}</td>
                      <td>{data.TotalRetainersAcquired}</td>
                      <td>{data.TotalDocumentsSent}</td>
                      <td>{data.TotalShowedAppointments}</td>
                      <td>{data.RetainersSet}</td>
                      <td>{data.TotalLiveTransferSent}</td>
                      <td>
                        <img
                          src={editIcon}

                          alt="Edit"
                          style={{
                            cursor: 'pointer',
                            marginRight: '5px',
                            filter: 'brightness(1.2)',
                            transition: 'filter 0.3s ease-in-out',
                            borderRadius: '20%',
                          }}
                          className="retainer-icon-hover-glow"
                          onClick={() => handleEditClick(data)}
                        />
                        <img
                          src={delIcon}

                          alt="Delete"
                          style={{
                            cursor: 'pointer',
                            marginLeft: '5px',
                            filter: 'brightness(1.2)',
                            transition: 'filter 0.3s ease-in-out',
                            borderRadius: '20%',
                          }}
                          className="retainer-icon-hover-glow"
                          onClick={() => handleDeleteClick(data.Id)}
                        />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </div>
          ) : (
            <p>No data available for this agent.</p>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose} style={{ margin: '1rem', border: '1px solid #a2a9b2', borderRadius: '4px', }}>Close</Button>
        </Modal.Footer>
      </CustomModal>

      {/* Edit Modal */}
      <Modal show={showEditModal} onHide={() => setShowEditModal(false)} centered>
        <Modal.Header closeButton>
          <Modal.Title>Edit Agent Data</Modal.Title>
        </Modal.Header>
        <Modal.Body>
			<Form>
			  <Form.Group controlId="TotalPhoneCallsMade">
			    <Form.Label>Total Phone Calls Made</Form.Label>
			    <Form.Control
			      type="number"
			      name="TotalPhoneCallsMade"
			      value={editData.TotalPhoneCallsMade || ''}
			      onChange={handleEditChange}
			    />
			  </Form.Group>

			  <Form.Group controlId="TotalMessagesSent">
			    <Form.Label>Total Messages Sent</Form.Label>
			    <Form.Control
			      type="number"
			      name="TotalMessagesSent"
			      value={editData.TotalMessagesSent || ''}
			      onChange={handleEditChange}
			    />
			  </Form.Group>

			  <Form.Group controlId="TotalEmailsSent">
			    <Form.Label>Total Emails Sent</Form.Label>
			    <Form.Control
			      type="number"
			      name="TotalEmailsSent"
			      value={editData.TotalEmailsSent || ''}
			      onChange={handleEditChange}
			    />
			  </Form.Group>

			  <Form.Group controlId="TotalContactReachOut">
			    <Form.Label>Total Contact Reach Out</Form.Label>
			    <Form.Control
			      type="number"
			      name="TotalContactReachOut"
			      value={editData.TotalContactReachOut || ''}
			      onChange={handleEditChange}
			    />
			  </Form.Group>

			  <Form.Group controlId="TotalDisqualifiedLeads">
			    <Form.Label>Total Disqualified Leads</Form.Label>
			    <Form.Control
			      type="number"
			      name="TotalDisqualifiedLeads"
			      value={editData.TotalDisqualifiedLeads || ''}
			      onChange={handleEditChange}
			    />
			  </Form.Group>

			  <Form.Group controlId="TotalAppointmentsSet">
			    <Form.Label>Total Appointments Set</Form.Label>
			    <Form.Control
			      type="number"
			      name="TotalAppointmentsSet"
			      value={editData.TotalAppointmentsSet || ''}
			      onChange={handleEditChange}
			    />
			  </Form.Group>

			  <Form.Group controlId="TotalRetainersAcquired">
			    <Form.Label>Total Retainers Acquired</Form.Label>
			    <Form.Control
			      type="number"
			      name="TotalRetainersAcquired"
			      value={editData.TotalRetainersAcquired || ''}
			      onChange={handleEditChange}
			    />
			  </Form.Group>

			  <Form.Group controlId="TotalDocumentsSent">
			    <Form.Label>Total Documents Sent</Form.Label>
			    <Form.Control
			      type="number"
			      name="TotalDocumentsSent"
			      value={editData.TotalDocumentsSent || ''}
			      onChange={handleEditChange}
			    />
			  </Form.Group>

			  <Form.Group controlId="TotalShowedAppointments">
			    <Form.Label>Total Showed Appointments</Form.Label>
			    <Form.Control
			      type="number"
			      name="TotalShowedAppointments"
			      value={editData.TotalShowedAppointments || ''}
			      onChange={handleEditChange}
			    />
			  </Form.Group>

			  <Form.Group controlId="RetainersSet">
			    <Form.Label>Retainers Set</Form.Label>
			    <Form.Control
			      type="number"
			      name="RetainersSet"
			      value={editData.RetainersSet || ''}
			      onChange={handleEditChange}
			    />
			  </Form.Group>

			  <Form.Group controlId="TotalLiveTransferSent">
			    <Form.Label>Total Live Transfers Sent</Form.Label>
			    <Form.Control
			      type="number"
			      name="TotalLiveTransferSent"
			      value={editData.TotalLiveTransferSent || ''}
			      onChange={handleEditChange}
			    />
			  </Form.Group>
			</Form>
        </Modal.Body>
        <Modal.Footer style={{ marginBottom: '1rem', marginRight: '1rem' }}>
          <Button variant="secondary" onClick={() => setShowEditModal(false)} style={{ border: '1px solid #a2a9b2', borderRadius: '4px' }}>Cancel</Button>
          <Button variant="primary" onClick={handleSaveChanges}>Save Changes</Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default EditButton;
