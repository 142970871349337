import React, { useState, useEffect } from "react";
import DateRange from "../components/RetainerTracker/DateRange/DateRange.js";
import AllCampaign from "../components/RetainerTracker/AllCampaign/AllCampaign.js";
import RetainerAPAPTable from "../components/RetainerTracker/RetainerAPAPTable/RetainerAPAPTable.js";
import RetainerB2BTable from "../components/RetainerTracker/RetainerB2BTable/RetainerB2BTable.js";
import RetainerCAMPTable from "../components/RetainerTracker/RetainerCAMPTable/RetainerCAMPTable.js";
import RetainerMVATable from "../components/RetainerTracker/RetainerMVATable/RetainerMVATable.js";
import RetainerTALCTable from "../components/RetainerTracker/RetainerTALCTable/RetainerTALCTable.js";
import RetainerNECTable from "../components/RetainerTracker/RetainerNECTable/RetainerNECTable.js";
import RetainerNECACQTable from "../components/RetainerTracker/RetainerNECACQTable/RetainerNECACQTable.js";
import RetainerRoundUpTable from "../components/RetainerTracker/RetainerRoundUpTable/RetainerRoundUpTable.js";
import RetainerDEPOTable from "../components/RetainerTracker/RetainerDEPOTable/RetainerDEPOTable.js";


import Navbar from "../components/Navbar/Navbar.js";
import axios from 'axios';

function Retainer() {
  const [retainers, setRetainers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const response = await axios.get(
          `http://statistics-staging.viribuzmedia.com/umbraco/Api/ViribuzAgentRetainer/GetRetainersByDateRange?startDate=${startDate.toISOString().split('T')[0]}&endDate=${endDate.toISOString().split('T')[0]}`,
          {
            headers: {
              Accept: 'application/json',
            },
          }
        );
        setRetainers(response.data.data);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching data:', error);
        setLoading(false);
      }
    };

    fetchData();
  }, [startDate, endDate]);

  return (
    <>
      <Navbar />
      <DateRange
        startDate={startDate}
        setStartDate={setStartDate}
        endDate={endDate}
        setEndDate={setEndDate}
      />
      <AllCampaign retainers={retainers}/>
      <RetainerAPAPTable />
      <RetainerB2BTable />
      <RetainerCAMPTable />
      <RetainerMVATable />
      <RetainerNECTable />  
      <RetainerNECACQTable />
      <RetainerRoundUpTable />
      <RetainerTALCTable />
      <RetainerDEPOTable />
    </>
  );
}

export default Retainer;
